@import "/public/css/breakpoints.scss";

.form-heading {
  text-align: center;

  @include breakpoint("tablet") {
    text-align: center;
  }
}

#logo_heading {
  display: flex;
  flex-direction: column;

  @include breakpoint("tablet") {
    flex-direction: row;
  }
}

#ourSage_logo_mobile {
  margin-top: 2px;
  display: block;

  @include breakpoint("tablet") {
    display: none;
  }
}

#ourSage_logo_tablet {
  margin-top: 2px;
  display: none;

  @include breakpoint("tablet") {
    display: block;
  }
}

#prev_button,
#prev_light_button {
  margin-top: -12px;
}

#prev_button {
  cursor: pointer;
}

#exit_button {
  margin-top: -7px;
}

#back_button {
  text-align: left;

  @media (min-width: 576px) {
    text-align: right;
  }
}
